import { useEffect } from 'react';
import Head from 'next/head'
import { ToastContainer, Zoom } from 'react-toastify';
import '../scss/main.scss';
import LogRocket from 'logrocket';

export default function MyApp({ Component, pageProps }) {

    useEffect(() => {
        if(process.env.NEXT_PUBLIC_ENV === "production") {
            LogRocket.init('i7rd9v/admission', {
                release: '1.0.0',
                dom: {
                    textSanitizer: false,
                    inputSanitizer: false,
                },
                console: {
                    isEnabled: false,
                }
            });
        }
    }, []);

    return (
      <>
        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Head>
        <Component {...pageProps} />
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            transition={Zoom}
            closeOnClick
            pauseOnVisibilityChange
            limit={3}
            pauseOnHover={false}
        />
      </>
    );
}